.pagination {
    list-style: none;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 2px solid #ccc;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    .hidden {
      display: none;
    }
  
    li {
      font-size: 1.4rem;
      border: 1px solid #ccc;
      min-width: 3rem;
      height: 3rem;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:not(.active) {
        
      background-color: #cccccc88;
      }
    }
    p {
      margin-left: 1rem;
      .page {
        color: var(--color-danger);
      }
    }
  }
  .active {
    background-color: var(--color-danger);
    color: #fff;
  }

  @media screen and (max-width: 800px) {
    .pagination {
      li {
        font-size: calc(min(3.5vw, 1.5em));
      }
      p {
        font-size: calc(min(3.5vw, 1.5em));
      }
    }
  }

  @media screen and (max-width: 400px) {
    .pagination {
      li {
        font-size: calc(min(3vw, 1.5em));
        min-width: 0.7em;
        height: 2em;
        padding: 0.8em;
      }
      p {
        font-size: calc(min(3.1vw, 1.5em));
        // min-width: 0.7em;
        // height: 2em;
      }
    }
  }