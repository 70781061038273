.sectionParallax {
  // position: relative;
  // position: absolute;
  height: fit-content;
  // max-height: 100%;
  position: relative;
  min-height: fit-content;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  flex-wrap: wrap;
  top: 0;
  // left: 0;
  // background-color: #fff;

  &::after {
    display: none;
  }
  
  .imageGroup {
    overflow: hidden;
    max-height: 100%;
    max-width: 100vw;
    .road {
      overflow: hidden;
      transform-origin:  bottom;
    }
    .moon {
      object-fit: contain;
      top: -20vh;
      transform-origin: right;
    }

    img {
      position: absolute;
      // display: block;
      // float: left;
      // top: -35%;
      top: 0;
      max-width: 100vw;
      height: 100vh;
      width: 100%;
      // max-height: 200vh;
      object-fit: fill;
      // background: no-repeat center center;
      pointer-events: none;
      // max-width: 100%;
      // height: 100vw;
      transition: transform 1s ease-out, top 1s ease-out, left 1s ease-out;
    }
    @media screen and (max-width: 800px) {
      .moon {
        // width: 100%;
        // top: -10%;
        // object-fit: contain;
      }
      .mountain {
        object-fit: cover;
      }
      .bg {
        object-fit: fill;
      }
    }

  }

  // To mix images together !!
  // &::before {
  //   content: "";
  //   position: absolute;
  //   z-index: 1;
  //   bottom: 0;
  //   width: 100%;
  //   height: 100px;
  //   background: linear-gradient(to top, #0a2a43, transparent);
  // }

  .sectionBody {
    display: flex;
    flex-direction: column;
    position: relative;
    // height: 100vh;
    margin-top: 7rem;
    justify-content: center;
  
    .niceButton {
      z-index: 5;
      // cursor: pointer;
      display: inline-block;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;
      text-shadow: 2px 2px #000000;
      width: 100%;
      max-width: 170px;
      text-align: center;
      background: transparent;
      height: 50px;
      line-height: 50px;
      color: #fff;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      font-size: calc(min(2rem, 5vw));
      font-weight: 500;
      letter-spacing: 1.3px;
      -webkit-transition: all 400ms linear 0s;
      transition: all 400ms linear 0s;
      padding: 0px;
      &:hover {
        text-decoration: none;
        background: rgba(225, 51, 45, 0);
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: pointer;
        rect {
          stroke-width: 3;
          stroke-dasharray: 20, 310;
          stroke-dashoffset: 25;
          -webkit-transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
      svg {
        height: 50px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        rect {
          fill: none;
          stroke: #fff;
          stroke-width: 3;
          stroke-dasharray: 422, 0;
        }
      }
    }
  
  
    .cardsContainer {
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 5vh;
      .card {
        padding: .5vw;
        border-radius: 2vw;
        height: calc(min(30vw, 30rem));
        background: linear-gradient(to right, #ffffff 0%, #c5c5c5 100%);
        width: 25vw;
        aspect-ratio: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        
        h2 {
          position: relative;
          // z-index: 1;
          // top: 5vh;
          color: #fff;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
          transition: all 1s ease-out;
        }
        @media screen and (max-width: 700px) {
          width: 30vw;
          height: 45vw;
        }
        @media screen and (max-width: 400px) {
          width: 40vw;
          height: 55vw;
        }
        &:hover h2::after {
          width: 95%;
        }
        a{
          z-index: 10;
          align-self: center;
          width: fit-content;
          font-weight: 500;
          font-size: calc(min(3.5vw, 1rem));
          @media screen and (max-width: 700px) {
            font-size: calc(min(3.5vw,.8rem));
          }
  
          position: relative;
          background: linear-gradient(to right, #cacaca 0%, #797979 100%);
          padding: 1% 8%;
          margin-top: 1vw;
          margin-bottom: calc(min(1rem, 2vw));
          border-radius: .3rem;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
          &:hover {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
          }
        }
        h2 {
          color: black;
          display: flex;
          flex: 1;
          align-items: center;
          position: relative;
          font-size: calc(min(1rem, 5vw));
          // margin-bottom: calc(min(1rem, 1vw));
          line-height: 1.2;
          padding: calc(min(1vw, .4rem));
          
          @media screen and (max-width: 600px) {
              font-size: calc(min(4vw, .8rem));
          }
          text-align: center;
          justify-content: center;
          &::after {
            content: "";
            position: absolute;
            bottom: -.1vw;
            width: 0%;
            display: block;
            left: 2.5%;
            border-top: 3px solid rgb(185, 185, 185);
            transition: width .5s cubic-bezier(0.215, 0.610, 0.355, 1);
          }
        }
        .imgContainer {
          // flex-grow: 1;
          border-radius: 2vw;
          overflow: hidden;
          // @media screen and (max-width: 900px) {
          //   height: calc(min(15vw, 4rem));
          // }
          // height: calc(min(20vw, 10rem));
          height: 70%;
          
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: transform 1s ease-in-out;
          }
          transform-style: preserve-3d;
          &:hover {
            img {
              -webkit-transform: scale(1.3);
              transform: scale(1.3);
            }
          }
  
        }
      }
    }
  
    .paginationContainer {
      display: flex;
      justify-content: center;
  
      
    }
  }

  
}





.contact {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  left: 0;
  z-index: 5;
  border-radius: 50%;
  // aspect-ratio: 1;
  width: 20%;
  height: 5%;
  bottom: 10%;

  .callMe {
    // margin-top: calc(min(15%, 25vw));
    width: calc(min(17vw, 4.3rem));
    // height: calc(min(17vw, 4.3rem));
    // bottom: 5vh;
    & > * {
      padding: calc(min(2.5vw, 0.8rem));
      background: linear-gradient(to right, #1a9ccf 0%, #0d3379 100%);
    }
  }
  a {
    align-items: center;
    margin-left: 5%;
    display: flex;
    cursor: pointer;
    // top: 0;
    width: calc(min(15vw, 4rem));
    height: calc(min(15vw, 4rem));
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
    &:hover {
      
      box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
    }
    & > * {
      color: #d4d2d2;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: left;
      align-content: flex-end;
      background: linear-gradient(to right, #1acf7b 0%, #0d7946 100%);
      // width: 5rem;
      width: 100%;
      height: 100%;
      // aspect-ratio: 1;
      color: #fff;
      font-size: calc(min(4.5vw, 1.1rem));
      // margin-top: auto;
      border-radius: 50%;
      // background: radial-gradient(circle at 20% 20%, #ffcfcf, #ef7676 10%, #be0808 80%, #450606 100%);
      // background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
      opacity: 0.7;
      z-index: 5;
      cursor: pointer;
    }
  }
}

.scrollTop {
  position: absolute;
  // width: 100%;
  height: calc(min(15vw, 5rem));
  transform-origin: center center;
  right: 0;
  display: flex;
  bottom: 5%;
  justify-content: right;
  margin-right: 10%;
  -webkit-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
  transform: scale(0);
  // background-color: var(--dark-blue);
  // background: radial-gradient(circle at 20% 20%, #ffcfcf, #3df7a0 10%, #0e8a50 80%, #450606 100%);
  &:hover >* {    
    background: linear-gradient(to right, #359381 0%, #58dbc5 100%);
  }
  > * {
    background: linear-gradient(to right, #1acf7b 0%, #0d7946 100%);
    // width: 5rem;
    height: 100%;
    width: auto;
    // aspect-ratio: 1;
    color: #fff;
    font-size: calc(min(4.5vw, 1.1rem));
    // margin-top: auto;
    align-items: center;
    border-radius: 50%;
    // background: radial-gradient(circle at 20% 20%, #ffcfcf, #ef7676 10%, #be0808 80%, #450606 100%);
    // background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    opacity: 0.7;
    z-index: 5;
    cursor: pointer;
    // box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
  }
}
