.hakkimizda {
    min-height: 100vh;
    padding-top: 20vh;
    padding-inline: 10vw;
    p {
        text-align: justify;

    }

    .midContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        padding: 10% 0;
        img {
            object-fit: contain;
            height: 100%;
            width: 80vw;
            max-height: 80vw;
            overflow: hidden;
        }
        &::after {
            
            content: "";
            display: block;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.158);;
            width: 100%;
            bottom: 0;
            margin-top: 15%;
        }
    }

    .bottomSection {
        .topsentence{
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                width: calc(min(12vw, 5rem));
                height: calc(min(12vw, 5rem));
                background-color: rebeccapurple;
                color: white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: calc(min(6vw, 3rem));
                font-weight: bold;
            }
            h3 {
                padding-left: 1%;
            }
        }
    }
}