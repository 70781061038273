.whatsapp {
    align-items: center;
    position: fixed;
    z-index: 5;
    // margin-left: 5%;
    display: block;
    cursor: pointer;
    // top: 0;
    margin-bottom: 5vh;
    bottom: 0%;
    left: 5%;
    width: calc(min(16vw, 4rem));
    height: calc(min(16vw, 4rem));
    border-radius: 20%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
    transform-origin: center center;

    &:hover {
      transform: rotate(45deg);
    }
    
    & > * {
      padding: calc(min(2vw, 0.6rem));
      color: #d4d2d2;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: left;
      align-content: flex-end;
      background: linear-gradient(to right, #1acf7b 0%, #0d7946 100%);
      // width: 5rem;
      width: 100%;
      height: 100%;
      // aspect-ratio: 1;
      color: #fff;
      font-size: calc(min(4.5vw, 1.1rem));
      // margin-top: auto;
      border-radius: 20%;
      // background: radial-gradient(circle at 20% 20%, #ffcfcf, #ef7676 10%, #be0808 80%, #450606 100%);
      // background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
      opacity: 0.7;
      z-index: 5;
      cursor: pointer;
    }

    
  }