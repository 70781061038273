.home {
    // padding-top: 20vh;

        h1 {
            font-size: calc(min(8vw, 3rem));
        }

    .whatsappContainer{
        position: fixed;
        bottom: 5%;
        left: 5%;
    }

    .buttonContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        a {
            margin-inline: calc(min(2vw, 1rem));
            padding: calc(min(2vw, 1rem)) calc(min(6vw, 3rem));
            font-weight: 500;
            &:nth-of-type(1) {            
                background-color: var(--color-primary);
                &:hover {
                    background-color: var(--bg-primary);                
                }
            }
            &:nth-of-type(2) {
                background-color: #25282c;
                &:hover {
                    background-color: #2f3135;                
                }
            }
            &:hover {
                
            }
        }
    }

    .modalContainer {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 5%;
        border-radius: 10%;
        
        iframe {
            position: relative;
            border-radius: 2vw;
            width: 80vw;
            height: 45vw;
        }
    }

    section {
        .cardContainer {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            // flex-direction: row;
            a {      
                display: flex;
                justify-content: center;    
                .card {
                    width: 100%;
                    max-width: 30rem;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .icon {
                        background-color: var(--color-primary);
                        padding: 15px;
                        border-radius: 50%;
                        align-self: center;
                        // transition: transform 1s ease-out;
                    }
                    &:hover .icon {
                        padding: 10px;
                        transform-origin: center center;
                        transition: padding 1s ease-out;
                    }
                }
            }
        }


    }


    .testimonial {
        h5::before {
            content: '';
            display: block;
            // padding: 1vw;
            transform: scale(.5);
            width: 4vh;
            height: 3vh;
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2218%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%2013.481c0-2.34.611-4.761%201.833-7.263C3.056%203.716%204.733%201.643%206.865%200L11%202.689C9.726%204.382%208.777%206.093%208.152%207.824c-.624%201.73-.936%203.578-.936%205.545V18H0v-4.519zm13%200c0-2.34.611-4.761%201.833-7.263%201.223-2.502%202.9-4.575%205.032-6.218L24%202.689c-1.274%201.693-2.223%203.404-2.848%205.135-.624%201.73-.936%203.578-.936%205.545V18H13v-4.519z%22%20fill%3D%22%236163FF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E');
            background-repeat: no-repeat;
        }
        .cardContainer .card .bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            .left {        
                display: flex;
                flex-direction: row; 
                align-items: center; 
                width: 100%;
                height: 100%;
                .starsrating {
                    font-size: calc(min(3vw, 2.1rem));
                }
                p, h6 {
                    margin: 0;
                    line-height: none;
                    color: #eceded;
                    font-weight: 300;
                    padding-top: 1%;
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                justify-content: right;
                white-space: nowrap;
                h4 {
                    padding: 0;
                    margin: 0;
                    font-weight: 200;
                    font-size: calc(min(3.2vw, 1.4rem));
                    text-align: right;
                }
            }
        }
    }


    .location {
        .inner {
            display: flex;
            flex-direction: row;
            @media screen and (max-width: 450px) {
                flex-direction: column;         
            }
            align-items: center;
            height: calc(min(45vh, 35rem));
            justify-content: center;
            background-color: #5658dd2d;
            position: relative;
            overflow: hidden;
            margin-bottom: 10vh;
            margin-top: 10vh;
            padding: 20px 5%;
            width: 100%;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                background-image: url("../../assets/images/bgicon.svg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 100% 100%;
            }
            h2 {
                width: 50%;
                text-align: left;
                font-size: calc(min(4.2vw, 3rem));
                @media screen and (max-width: 450px) {
                    display: none;         
                }
            }
            iframe {
                height: 100%;
                width: 60%;
                @media screen and (max-width: 450px) {
                    width: 100%;
                }
                border: 8px ridge rgba(157, 163, 38, 0.781);
                border-radius: 20px;
                padding: 0px;
                z-index: 1;
            }
            
        }
    }

    

    .split {
        display: flex;
        flex-direction: column;
        // padding-inline: 0;
        .row {
            display: flex;
            flex-direction: row;
            margin-bottom: 10%;
            .left, .right {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                width: 100%;
                justify-content: center;                
                overflow: hidden;
                &:nth-of-type(even) {
                    margin-left: 5%;
                }
                h6,h3,p{            
                    text-align: left;
                    margin: 0;
                    padding-right: 2%;
                }
                p {
                    padding-top: 3%;
                    font-size: calc(min(4.2vw, 1.3rem));
                    font-weight: 300;
                }
                img {
                    border: 2px solid white;
                    width: 100%;
                    -webkit-transition: transform 1s ease-in-out;
                    transition: transform 1s ease-in-out;
                    height: 40vw;
                    object-fit: cover;
                    align-self: center;
                    &:hover, &:active {
                        position: relative;
                        -webkit-transform: scale(1.2);
                        transform: scale(1.4);
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 650px)  {
    .home {
        .split {
            .row {
                flex-direction: column;
                &:nth-of-type(even) {
                    flex-direction: column-reverse;
                    
                }
                .left, .right {
                    padding-left: 0 !important;
                    margin: 2vh 2vw;
                    margin-left: 0 !important;
                    >* {
                        text-align: justify !important;
                    }
                }
            }
        }
    }
}



.embla {
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: 19rem;
    padding: 1.6rem;

    
    .embla__dots {
        // height: 70%;
        // bottom: 0rem;
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            margin-right: calc(min(5vw, 1rem));
        }
      }
  }
  .embla__viewport {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
    flex-direction: row;
    height: auto;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
  }
  .embla__slide__img {
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
  }
  .embla__slide__number {
    width: 4.6rem;
    height: 4.6rem;
    z-index: 1;
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    border-radius: 50%;
    background-color: rgba(var(--background-site-rgb-value), 0.85);
    line-height: 4.6rem;
    font-weight: 900;
    text-align: center;
    pointer-events: none;
  }
  .embla__slide__number > span {
    color: var(--brand-primary);
    background-image: linear-gradient(
      45deg,
      var(--brand-primary),
      var(--brand-secondary)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.6rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  