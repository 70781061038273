.telephone {
  // width: calc(min(17vw, 4.3rem));
  // height: calc(min(17vw, 4.3rem));
  z-index: 5;
  align-items: center;
  position: fixed;
  right: 5%;
  display: flex;
  cursor: pointer;
  // top: 0;
  margin-bottom: 5vh;
  bottom: 0;
  width: calc(min(16vw, 4rem));
  height: calc(min(16vw, 4rem));
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
  transform-origin: center center;

  .pulse {
    position: absolute;
    background: transparent;
    border: 2px solid wheat;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
  }

  &:hover,
  &:active {
    transform: rotate(42deg);
  }

  & > * {
    padding: calc(min(2.5vw, 0.8rem));
    border-radius: 50%;
    background: linear-gradient(to right, #1a9ccf 0%, #0d3379 100%);
    color: #d4d2d2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: left;
    align-content: flex-end;
    //   background: linear-gradient(to right, #1acf7b 0%, #0d7946 100%);
    // width: 5rem;
    width: 100%;
    height: 100%;
    // aspect-ratio: 1;
    color: #fff;
    font-size: calc(min(4.5vw, 1.1rem));
    // margin-top: auto;
    border-radius: 50%;
    // background: radial-gradient(circle at 20% 20%, #ffcfcf, #ef7676 10%, #be0808 80%, #450606 100%);
    // background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    opacity: 0.7;
    cursor: pointer;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
