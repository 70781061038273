.container {
    max-width: 100%;
    height: 400vh;
    background: url("../../assets/images/perde2.jpg") repeat center;
    background-size: contain;

    .split {
        display: flex;
        flex-direction: column;
        // padding-inline: 0;
        .row {
            max-width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: row;
            &:nth-of-type(odd) {
                .left {
                background-color: var(--bg-color);
                }
            }
            
            &:nth-of-type(even) {
                .right {
                background-color: var(--bg-color);
                }
            }
            .left, .right {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-content: center;               
                // overflow: hidden;

                .iconset {
                    max-width: 100%;
                    display: flex;
                    justify-content: center;
                }

                .emptyspace {
                    background-color: var(--bg-color);                    
                }
                h6,h3,p{            
                    text-align: center;
                    margin: 0;
                }
                p {
                    padding-top: 3%;
                    font-size: calc(min(4vw, 1.5rem));
                    font-weight: 300;
                }
                img {
                    border: 2px solid white;
                    width: 100%;
                    height: 100%;
                    -webkit-transition: transform 1s ease-in-out;
                    transition: transform 1s ease-in-out;
                    object-fit: cover;
                    overflow: hidden;
                    align-self: center;
                    &:hover, &:active {
                        position: relative;
                    }
                }
            }
        }
    }    
}

@media screen and (max-width: 650px)  {
    .container {
        height: auto;
        justify-content: center;
        align-content: center;
        .split {
            .row {
                height: auto;
                flex-direction: column;
                justify-content: center;
                align-content: center;

                
                &:nth-of-type(odd) {
                    .left {
                    background-color: var(--bg-color);
                    }
                    .right {
                        // height: 30vh !important;
                    }
                }
                &:nth-of-type(even) {
                    flex-direction: column-reverse;  
                    .left {
                    // background-color: var(--bg-color);
                    }
                    .right {
                        background-color: var(--bg-color);
                    }
                }

                .left, .right {
                    height: 40vh;
                    display: flex;
                    flex-direction: column;
                    padding-left: 0 !important;
                    padding: 0;
                    // overflow: visible;
                    // margin: 2vh 2vw;
                    >* {
                        // text-align: center;
                    }
                }
            }
        }
    }
}