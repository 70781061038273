.footer {
  // position: relative;
  position: relative;
  // position: absolute;
  // top: 95.5%;
  bottom: 0;
  margin-top: 10vh;
  // margin-bottom: -5rem;
  // top: 100%;
  width: 100%;
  height: 10rem;
  // background-color: var(--dark-blue);
  // background: radial-gradient(circle at 20% 20%, #ffcfcf, #3df7a0 10%, #0e8a50 80%, #450606 100%);
  background: linear-gradient(to right, #4dceb691 0%, #050e0c8e 100%);
  color: #fff;
  font-size: calc(min(2vw, 0.7rem));
  // margin-top: auto;
  display: flex;
  justify-content: left;
  text-align: left;
  padding-left: 5%;
  align-items: center;
  z-index: 4;
  p {
    color: #fff;
    text-align: left;
    font-size: calc(min(3vw, .8rem));
  }

  .middle {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    margin: 1vw;
    transform: scale(.5);
    a {   
      transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
      transform-origin: center center;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      margin: 0;   
      img {
        width: calc(min(8.5vw, 4.1rem));
        height: calc(min(8.5vw, 4.1rem));
      }      
      p {
        font-size: calc(min(5vw, 0.8rem));
        font-family: "Aladin", sans-serif;
        padding: 0;
        margin: 0;
      }
      &:hover {
        transform: scale(0.9);
      }
    }
  }
}
