header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: calc(min(10%, 2rem)) 6vw !important;
    // padding-top: ;
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-direction: row;
    align-items: center;
    z-index: 10;
    a{        
        color: #fff;
    }

    .left {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        z-index: 1;
        .logoContainer {
            display: block;
            height: auto;
            max-width: 100%;
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.9);
            
            >img {
                // margin-right: 15%;
                // background: none;
            width: 100px;
            // aspect-ratio: 2,95;
                
            }
        }
    }

    .right {
        display: flex;
        flex-grow: 1;
        flex: 1;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        .menu-icon {
            cursor: pointer;
            display: none;
        }
        nav {
            display: flex;
            justify-content: space-between;
            width: 100%;
            z-index: 6;
            >ul {
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                justify-content: flex-end;
                width: 100%;
                
                // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
                padding: calc(min(1.3vw, .5rem)) 0;
                // height: 60px;
                // height: 20px;
                // border: 1px solid rgba(255,255,255,0.1);

                .desteklerLine {

                    ul {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        @media screen and (min-width: 701px)  { 
                            margin-top: 1.5rem;
                        }
                        a {
                            margin-top: .5rem;
                            @media screen and (min-width: 701px)  { 
                                background-color: rgba(0, 0, 0, 0.863);
                            }
                        }
                    }
                }
                
                li {
                    padding: calc(min(1.3vw, .5rem)) calc(min(1.3vw, .8rem));
                    a {
                        // &::after {
                        //     content: "";
                        //     position: absolute;
                        //     width : 100%;
                        //     height: 100%;
                        //     z-index: -1;
                        //     left: 0;
                        //     // top: 0;
                        //     backdrop-filter: blur(4px);
                        //     border-radius: 5rem;                            
                        // }
                        position: relative;
                        text-decoration: none;
                        padding: calc(min(1.4vw, 1.2rem)) calc(min(2vw, 2.3rem));
                        border-radius: 5rem;
                        @media screen and (max-width: 900px) and (min-width: 700px) {
                            font-size: calc(min(1.2rem, 5.5vw));                            
                        }
                        margin: 0 calc(min(1vw, .1rem));
                        font-weight: 600;
                        text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
                        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
                        // backdrop-filter: blur(20px);
                        // border: 1px solid rgba(255,255,255,0.1);
                        transition: .5s;
        
                        @media screen and (min-width: 701px)  { 
                            span {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 5rem;
                                font-weight: 600;
                                text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
                                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
                                background: #7072ce5e;
                                border-radius: 30px;
                                // z-index: -1;
                                transform: scale(0);
                                opacity: 0;             
                                transition: transform .3s ease;       
                            }                    
                            &:hover span {
                                transform: scale(1);
                                opacity: 1;
                                z-index: -1;
                            }
                        }
        
                        &.active {
                            background: #5658ddb4;
                            opacity: 1;
                            color: #fff;
                        }
        
                        // &:hover span {
                        //     transform: scale(1);
                        //     opacity: 1;
                        // }
                        
                        // &:hover {
                        //     // background: #359382a9;
                        //     color: #fff;
                        // }
                    }

                    

                }
            }
        }
    }
    
    
}

.fullscreenwrapper {
    position: absolute;
    background-color: #000000a1;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
}

@media screen and (max-width: 700px)  {
    header {
        // position: absolute;
        // align-items: flex-start;
        padding-top: calc(min(10%, 5rem));

        .right {
            position: absolute;
            display: flex;
            flex-direction: column;
            z-index: 6;
            width: fit-content;
            right: 5%;
            .menu-icon {
                display: flex;
                align-self: flex-end;
                z-index: 7;
                font-size: calc(min(15vw, 3rem));
                padding: 1vw;
                padding-top: 0;
                margin: calc(min(5vw, 0.5rem)) calc(min(.3vw, 1.5rem));
                margin-top: 0;
                z-index: 8;
                cursor: pointer;
            }
            .active {
                border-radius: 15px;
                backdrop-filter: blur(2px);
            }
            nav {
                position: absolute;
                overflow-x: hidden;
                display: flex;
                top: 100%;
                right: 0;
                width: calc(min(60vw, 25rem));
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: center;
                // display: none;
                >ul {
                    background: rgba(255,255,255,0.1);
                    backdrop-filter: blur(6px);
                    padding: 4vw;
                    border-radius: 10px;
                    width: 100%;
                    // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

                    .desteklerLine {
                        display: flex;
                        flex-direction: column;

                        ul {
                            padding-left: 10%;
                            position: relative;
                            flex-direction: column;
                            a {
                                margin-top: 0;
                                &.active {
                                    color: #fff;
                                    background: none;
                                    backdrop-filter: none;
                                    position: relative;
            
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        margin: 0 auto;
                                        width: 100%;
                                        height: 2px;
                                        background-color: #fff;
                                    }
                                }
                            }
                        }
                    }

                    li {
                        padding: .5rem;   
                        
                        a {
                            width: 100%;
                            text-align: left;
                            // border-radius: 10px;
                            box-shadow: none;
                            border: none;

                            &::after {                                
                                backdrop-filter: none;
                            }
        
                            &.active {
                                color: #fff;
                                background: none;
                                backdrop-filter: none;
        
                                &::after {
                                    content: "";
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    margin: 0 auto;
                                    width: 100%;
                                    height: 2px;
                                    background-color: #fff;
                                }
                            }
                            
                            &:hover {
                                background: none;
                            }
                            
                        }
                    }
                }
            
                .show-ddlist {
                    // width:fit-content;
                    display: flex;
                    flex-direction: column;
                }
                .hide-ddlist {
                    display: none;
                }                
            }

        }
        

        
        


    }
}
