.scrollTop {
    position: fixed;
    // width: 100%;
    height: calc(min(15vw, 4rem));
    transform-origin: center center;
    right: 0;
    display: flex;
    bottom: 20%;
    justify-content: right;
    margin-right: 10%;
    -webkit-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
    transform: scale(0);
    // background-color: var(--dark-blue);
    // background: radial-gradient(circle at 20% 20%, #ffcfcf, #3df7a0 10%, #0e8a50 80%, #450606 100%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
    &:hover >* {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
    transform: scale(0.9) !important;
    background: linear-gradient(to right, #fff349 0%, #d84747 100%);
    top: -50%;
    }
    &:active >*>* {
      transform: translateY(-50%);
      transition: transform 0.5s ease;
    }
    > * {
      background: linear-gradient(to right, #ffee00 0%, #d60000 100%);
      // width: 5rem;
      height: 100%;
      width: auto;
      // aspect-ratio: 1;
      color: #fff;
      font-size: calc(min(4.5vw, 1.1rem));
      // margin-top: auto;
      align-items: center;
      border-radius: 50%;
      // background: radial-gradient(circle at 20% 20%, #ffcfcf, #ef7676 10%, #be0808 80%, #450606 100%);
      // background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
      opacity: 0.7;
      z-index: 5;
      cursor: pointer;
      // box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
    }
  }