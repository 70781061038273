.footer-social {
    position: absolute;
    right: 0;
    margin-right: 3vw;
  ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 0;
    li {
      a {
        padding: 1vw;
        display: flex;
        list-style: none;
        background-color: transparent;
        fill: #fff;
        transition: all .3s ease-in-out;
        transform-origin: center center;
        &:hover {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
      }
    }
  }
}
