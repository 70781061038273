.hamburgerContainer {
	background: transparent;
	border: 0;
	outline: 0;
	cursor: pointer;
    width: 24px;
    height: 24px;
    margin: 1rem;
    transform: scale(1.5);
}
.screen-reader {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
.hamburger,
.hamburger-inner {
	display: block;
	pointer-events: none;
}

.hamburger {
	position: relative;
	width: 24px;
	height: 2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 24px;
	height: 2px;
	position: absolute;
	background: #fff;
}

.hamburger-inner {
	top: 50%;
	margin-top: -(2px * 0.5);
	transition-duration: 0.22s;
	-webkit-transition-duration: 0.22s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	-webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

	&::before,
	&::after {
		content: '';
		display: block;
	}

	&::before {
		top: -7px;
		-webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
		transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
	}

	&::after {
		bottom: -7px;
		-webkit-transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
		transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
	}

	.active & {
		-webkit-transform: rotate(225deg);
		transform: rotate(225deg);
		-webkit-transition-delay: 0.12s;
		transition-delay: 0.12s;
		-webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

		&::before {
			top: 0;
			opacity: 0;
			-webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
			transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
		}

		&::after {
			width: 24px;
			bottom: 0;
			-webkit-transform: rotate(-90deg);
			transform: rotate(-90deg);
			-webkit-transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;
			transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;
		}
	}
}
