.faqItem {
    display: flex;
    justify-content: center;
    .container {
        margin: 1% 5%;
        
        @media screen and (max-width: 550px)  { 
            margin: 1% 2%;
        }
        width: 100%;
        line-height: 15px;
        border-bottom: 1px solid rgba(209, 213, 219, 0.5);
        background: #f0f0f0;
        text-align: left;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    
        .button {
            padding: 2% 3%;
            width: 100%;
            position: relative;
            text-align: left;
            border: none;
            background: transparent;
            outline: none;
            cursor: pointer;
                
            &:hover .downArrow {
                color: rgb(32, 32, 32);
                transform: scale(1.5);
            }

            .topRow {
                display: flex;
                flex-direction: row;
                align-items: center;
                p {
                    display: inline-block;
                    color: black;
                    width: 90%;
                    vertical-align: baseline;
                    font-size: 1em;
                    font-weight: 600;
                    b {
                        color: var(--color-primary);
                        vertical-align: baseline;
                    }
                }

                .expandIcon {
                    &::before {
                        background: #0051c3;
                        margin-right: -21px;
                        content: "";
                        display: inline-block;
                        height: 3px;
                        width: 21px;
                        -webkit-transition: transform .5s ease;
                        transition: transform .5s ease;
                        // transform: rotate(180deg);
                    }
                    &::after {
                        // transform: rotate(90deg);
                        background: #0051c3;
                        content: "";
                        display: inline-block;
                        height: 3px;
                        width: 21px;                    
                        -webkit-transition: transform .5s ease;
                        transition: transform .5s ease;    
                    }
                    &[aria-expanded=false] {
                        &::before {                            
                            -webkit-transform: rotate(90deg);
                            transform: rotate(90deg);
                        }
                        &::after {
                            -webkit-transform: rotate(180deg);
                            transform: rotate(180deg);
                        }
                    }
                }
                
                
                    
                .downArrow {
                    flex: 1;
                    height: 20px;
                    transform-origin: center center;
                    transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
                }
                .upArrow {
                    flex: 1;
                    height: 20px;
                    transform: rotate(180deg);
                    transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
                }
            }
        }
    
        .answer {
            padding-top: 1%;
            color: black;
            overflow: hidden;
            white-space: pre-wrap;
            font-size: 0.9em;
        }
    }
}