@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Rancho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aladin&display=swap');

:root {
  /* --font-family: "Poppins", sans-serif; */
  --font-family: "Inter", sans-serif;
  --font-size: calc(min(2rem, 4vw));
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;

  --color-white: #fff;
  --color-dark: #333;

  --color-grey: #eee;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  --color-purple: #9d0191;
  --color-orange: #ff7722;
  --color-card: linear-gradient(to right, #035e4b 0%, #02ad88 100%);
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);

  --bg-color: #151719;
  --color-primary: #5658dd;
  --bg-primary: #7072ce;
  --color-secondary: #9ca9b3;
  --color-success: #28a745;
  --color-danger: orangered;
}

*:not(input) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  list-style: none;
}

html {
  /* font-size: 10px;˙ */
  /* height: 100%; */
  min-height: 100%;
  position: relative;
  min-height: 100vh;
  background: var(--bg-color);
}

body {
  font-family: var(--font-family);
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.container::after {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 100%;
  background-image: url("./assets//images/rightLines.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  height: 100px;
  top: 0;
  margin-top: 15vh;
  left: 0%;
  transform: translateX(-40vw) scale(3);
}



section {
  width: 100%;
  padding-inline: 3%;
  height: 100%;
  overflow: hidden;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

section::after {
  content: "";
  display: flex;
  position: relative;
  height: 2px;
  width: 100%;
  background: #02ad88;
  background-color: rgba(255, 255, 255, 0.158);
  bottom: -5vh;
}

.container {
  position: relative;
  max-width: 95vw;
  margin: 0 auto;
  padding: 0 .5vw;
  overflow-x: hidden;
}



/* Heading */
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  line-height: 1.2;
  color: #eceded;
  margin-bottom: calc(min(1.5rem, 4vw));
  margin-top: calc(min(1.5rem, 4vw));
  text-align: center;
}
h1 {
  font-size: calc(min(8vw, 5rem));
  font-weight: 800;
  line-height: 1.5;
}
h2 {
  font-size: calc(min(8vw, 2rem));
  font-weight: 800;
  line-height: calc(min(62px, 8vw));
}
h3 {
  font-size: calc(min(7vw, 1.4rem));
  line-height: 1.4;
}
h4 {
  font-size: calc(min(4.5vw, 1.2rem));
}
h5 {
  font-weight: 200;
  line-height: 1.4;
  font-size: calc(min(3.5vw, 1.1rem));
}
h6 {
  font-weight: 600;
  line-height: calc(min(25px, 5vw));
  font-size: calc(min(3.5vw, 1rem));
  color: var(--color-primary);
  text-transform: uppercase;
}

p {
  font-size: calc(min(5vw, 1.3rem));
  font-weight: 300;
  line-height: 1.4;
  color: var(--color-secondary);
}

a {
  font-size: calc(min(6vw, 1.2rem));
  font-weight: 600;
  border: 1px solid transparent;
  text-decoration: none;
  transform-origin: 20% 50%;
  transition: all 0.2s;
  cursor: pointer;
}

a:hover {
  color: #fff;
  /* transform: scale(1.05, 1.05); */
}

strong {
  font-weight: normal;
}

.--color-primary {
  color: var(--color-primary);
}
.--color-danger {
  color: orangered;
}
.--color-success {
  color: #28a745;
}

.--color-white {
  color: #fff;
}

/* Center Text */
.--text-center {
  text-align: center;
}

/* Card */
.--card {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}




.--btn {
  box-shadow: 2px 2px 8px 2px rgba(0,0,0,.5);
  font-size: calc(min(1.6rem, 4vw));
  font-weight: 400;
  padding: 6px 8px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  font-family: var(--font-family);
}

.--btn:hover {
  transform: translateY(-2px);
}

.--btn-lg {
  padding: 8px 10px;
}

.--btn-block {
  width: 100%;
}

.--btn-primary {
  color: #fff;
  background: #007bff;
}
.--btn-secondary {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.--btn-danger {
  color: #fff;
  background: orangered;
}
.--btn-red {
  color: #fff;
  background: rgb(230, 2, 2);
}

.--btn-success {
  color: #fff;
  background: #28a745;
}

.--square {
  aspect-ratio: 1 / 1;
  padding: 1vw 1vw;
  width: calc(min(6vw, 2rem));
  height: calc(min(6vw, 2rem));
  /* font-size: calc(min(4vw, 2rem)); */
  /* aspect-ratio: 1; */
}

/* Background */
.--bg-light {
  background: #fff;
}
.--bg-dark {
  background: var(--color-dark);
}
.--bg-primary {
  background: var(--color-primary);
}
.--bg-success {
  background: var(--color-success);
}
.--bg-grey {
  background: var(--color-grey);
}



.underlined {
  text-decoration: underline;
  text-underline-offset: 3px;
}




@media screen and (max-width: 800px) {
  .toast-container {
    margin-top: 12rem !important;
    max-width: 50vw;
  }
}
