.card {
  border: 1px solid transparent;
  border-radius: 2rem;
  padding: 2%;
  justify-content: center;
  text-align: center;
  // overflow: hidden;
  margin: calc(min(2vw, 5rem));
  margin-bottom: 5vw;
  box-shadow: var(--box-shadow);
  // background: var(--color-card);
}
