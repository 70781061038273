.sliderContainer {
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 1;
    h1 {
        position: absolute;
        width: 100%;
        z-index: 1;
        top: 50%;
        left: 50%;
        line-height: 1.4;
        pointer-events: none;
        transform: translate(-50%, -50%);
        margin: auto;
        font-size: calc(min(5rem, 10vw));
        text-shadow: 2px 2px rgb(37, 37, 37);
    }
  .embla {
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: 100vh;
    padding: 0;
    @media screen and (max-width: 450px) {
      // --slide-height: 100vh;
    }


    .embla__dots {
      // height: 70%;
      // bottom: 0rem;
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      button {
        margin-right: calc(min(5vw, 1rem));
        margin-top: -5vh;
      }
    }

    .embla__viewport {
      overflow: hidden;

      .embla__container {
        display: flex;
        flex-direction: row;
        height: auto;
        margin-left: calc(var(--slide-spacing) * -1);

        .embla__slide {
          flex: 0 0 var(--slide-size);
          min-width: 0;
          padding-left: var(--slide-spacing);
          position: relative;

          .embla__slide__number {
            width: 4.6rem;
            height: 4.6rem;
            z-index: 1;
            position: absolute;
            top: 0.6rem;
            right: 0.6rem;
            border-radius: 50%;
            background-color: rgba(var(--background-site-rgb-value), 0.85);
            line-height: 4.6rem;
            font-weight: 900;
            text-align: center;
            pointer-events: none;
          }
        }
      }
    }
  }
  .embla__slide__img {
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
  }

  .embla__slide__number > span {
    color: var(--brand-primary);
    background-image: linear-gradient(
      45deg,
      var(--brand-primary),
      var(--brand-secondary)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.6rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
