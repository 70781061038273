.embla {
    --slide-spacing: calc(min(1rem, 1vw));
    --slide-size: 100%;
    --slide-height: 42vw;
    --thumbs-slide-height: 10vw;
    @media screen and (max-width: 800px) {
      --slide-height: 50vw;
      --thumbs-slide-height: 15vw;      
    }
    --thumbs-slide-spacing: calc(min(.8rem, 1vw));
    --brand-primary: rgb(3, 8, 14);
    --brand-secondary: rgb(152, 130, 240);
    // padding: 1.6rem;
    padding-bottom: 0;
    padding-top: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }
  html {
    box-sizing: border-box;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  
  ul {
    list-style: none;
  }
  hr {
    height: 0;
  }
  abbr[title] {
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  samp,
  pre {
    font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier,
      monospace;
    font-size: 1em;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
    top: -0.5em;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: none;
    appearance: none;
  }
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  img,
  embed,
  iframe,
  object,
  audio,
  video {
    height: auto;
    max-width: 100%;
  }
  
  html {
    font-family: 'Inter', 'system-ui', -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol';
    letter-spacing: -0.02rem;
  }
  @supports (font-variation-settings: normal) {
    html {
      font-family: 'Inter var', 'system-ui', -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol';
    }
  }
    
  .embla__viewport {
    overflow: hidden;

    .embla__container {
      display: flex;
      flex-direction: row;
      height: auto;
      margin-left: calc(var(--slide-spacing) * -1);

      .embla__slide {
        flex: 0 0 var(--slide-size);
        min-width: 0;
        padding-left: var(--slide-spacing);
        position: relative;

        .content {
          position: absolute;
          z-index: 1000;
          text-align: center;
          top: 50%;
          left: 50%;
          opacity: 0;
          width: 50%;
          padding: calc(min(3rem, 2vw));
          display: flex;
          justify-self: center;
          align-items: center;
          flex-direction: column;
          transform: translateX(-50%);
          background: rgba(0, 0, 0, 0.4);
          //   visibility: hidden;
          h2 {
            font-size: calc(min(3.5rem, 4vw));
          }
          p {
            font-size: calc(min(1.8rem, 2vw));
          }
          a {
            font-size: calc(min(1.5rem, 2vw));
            padding: calc(min(.7rem, 1vw)) calc(min(1rem, 1.3vw));          
          }
          & > * {
            color: #fff;
            margin-bottom: calc(min(1rem, 1.5vw));
          }
        }
        .active {
          opacity: 1;
          animation: slide-up 1s ease 0.5s;
          animation-fill-mode: forwards;
        }

        .embla__slide__number {
          width: calc(min(4.6rem, 7vw));
          height: calc(min(4.6rem, 7vw));
          z-index: 1;
          position: absolute;
          top: 0.6rem;
          right: 0.6rem;
          border-radius: 50%;
          background-color: rgba(white, 0.85);
          line-height: 4.6rem;
          font-weight: 900;
          text-align: center;
          pointer-events: none;

          &> span {
              color: rgba(0, 0, 0, 0.60);
              // background-image: linear-gradient(45deg, var(--brand-primary), var(--brand-secondary));
              background-clip: text;
              // -webkit-background-clip: text;
              // -webkit-text-fill-color: transparent;
              font-size: calc(min(1.6rem, 4vw));
              position: absolute;
              left: 0;
              right: 0;
              top: 1px;
              bottom: 0;
              display: flex;
              align-items: center; 
              justify-content: center;
            }
        }
        .embla__slide__img {
          display: block;
          height: var(--slide-height);
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .embla-thumbs {
    // --thumbs-slide-spacing: 0.8rem;
    // --thumbs-slide-height: 9rem;
    margin-top: var(--thumbs-slide-spacing);

    .embla-thumbs__viewport {
      overflow: hidden;

      .embla-thumbs__container {
        display: flex;
        flex-direction: row;
        margin-left: calc(var(--thumbs-slide-spacing) * -1);

        .embla-thumbs__slide {
          flex: 0 0 28%;
          min-width: 0;
          padding-left: var(--thumbs-slide-spacing);
          position: relative;

          .embla-thumbs__slide__button {
            -webkit-appearance: none;
            background-color: transparent;
            touch-action: manipulation;
            display: block;
            text-decoration: none;
            cursor: pointer;
            border: 0;
            padding: 0;
            margin: 0;
            width: 100%;
            opacity: 0.2;
            transition: opacity 0.2s;

            .embla-thumbs__slide__number {
              width: calc(min(3rem, 4vw));
              height: calc(min(3rem, 4vw));
              z-index: 1;
              position: absolute;
              top: 0.3rem;
              right: 0.3rem;
              border-radius: 50%;
              background-color: rgba(white, 0.85);
              line-height: 3rem;
              font-weight: 500;
              text-align: center;
              pointer-events: none;

              &> span {
                color: rgba(0, 0, 0, 0.60);
                // background-image: linear-gradient(45deg, var(--brand-primary), var(--brand-secondary));
                background-clip: text;
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                font-size: calc(min(1.3rem, 2.5vw));
                position: absolute;
                top: 1px;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center; 
                justify-content: center;
              }
            }

            .embla-thumbs__slide__img {
              display: block;
              height: var(--thumbs-slide-height);
              width: 100%;
              object-fit: cover;
            }
          }
        }
        .embla-thumbs__slide--selected .embla-thumbs__slide__button {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes slide-up {
  0% {
    top: 50%;
  }
  100% {
    top: 30%;
  }
}


.embla__dot,
.embla__button {
  // pointer-events: all;
  --brand-primary: rgb(47, 112, 193);
  --brand-secondary: rgb(116, 97, 195);
  --brand-alternative: rgb(19, 120, 134);
  --background-site: rgb(249, 249, 249);
  // z-index: 1000;
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  will-change: transform;
  -webkit-transition: transform .2s;
  transition: transform .2s;
  &:hover  {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  &.embla__dot--selected {
    
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
.embla__dot {
  width: .7rem;
  height: .7rem;
  display: flex;
  align-items: center;
  background: radial-gradient(circle at 20% 20%, #ffffff, #ffcfcf 10%, rgb(151, 151, 151) 80%, #ffcfcf 100%);
  border-radius: 100%;
  margin-right: 1vw;
  margin-left: 1vw;
}
.embla__dot:after {
  background: var(--background-site);
  border-radius: 0.2rem;
  // width: 100%;
  height: 0.3rem;
  content: '';
}
.embla__dot--selected:after {
  // background: linear-gradient(
  //   45deg,
  //   var(--brand-primary),
  //   var(--brand-secondary)
  // );
}
// .embla__button {
//   color: var(--background-site);
//   position: absolute;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   top: 50%;
//   transform: translateY(-50%);
//   cursor: pointer;
//   width: 4rem;
//   height: 4rem;
// }
.embla__button--prev {
  left: 1.6rem;
}
.embla__button--next {
  right: 1.6rem;
}
.embla__button:disabled {
  opacity: 0.3;
}
.embla__button__svg {
  width: 65%;
  height: 65%;
}




// @media (min-width: 576px) {
//   .embla-thumbs__slide {
//       flex: 0 0 18%;
//   }
// }



.fullimg {
  // position: absolute;
  // z-index: 1000;
  // width: 100vw !important;
  // height: 100vh !important;
  // overflow: visible;
  cursor: zoom-out;
  position: absolute;
  z-index: 7;
  
  width: 100%;
  // height: 100vh !important;
  position: absolute;
  top: 10%;
  // left: 0 !important;
  // -webkit-transition: 3s ease-in-out;
  // transition: 3s ease-in-out;
  // -webkit-user-drag: auto;
  will-change: transform, border-radius, opacity;
  // -webkit-transform: scale3d(0.5,0.5,0.5);
  // transform: scale3d(0.5,0.5,0.5);
  display: block;
  -webkit-animation: scale .5s ease-in-out 0s forwards;
  animation: scale .5s ease-in-out 0s forwards;
}

@-webkit-keyframes scale {
  0% {
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    border-radius: 0%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    border-radius: 100%;
    // -webkit-transform: scale(0);
    transform: scale(0);
    // width: 10%;
    // height: 10%;
    // aspect-ratio: 1 !important;
    // top: 50%;
    // left: 50%;
  }
  100% {
    border-radius: 0%;
    transform: scale(1);
  }
}



.fullscreenwrapper {
  position: absolute;
  background-color: #000000a1;
  left: 0;
  top: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
}

  


